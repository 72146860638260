.stars {
    margin-bottom: 0.5em;
}

.reviews-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.checked {
    color: #0D4C5A;
}

.reviewer {
    margin-top: 0.5em;
    color: #0D4C5A;
    text-align: right;
}