
.content-container {
  text-align: left;
}

.progress-bar-container {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  width:180px;
  min-width: 180px;
}

.banner-container {
  margin-top: 100px
}