
@font-face {
  font-family: 'mabry-regular-pro';
  src: url('./assets/fonts/mabry-regular-pro.eot');
  src: url('./assets/fonts/mabry-regular-pro.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/mabry-regular-pro.woff2') format('woff2'),
       url('./assets/fonts/mabry-regular-pro.woff') format('woff'),
       url('./assets/fonts/mabry-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mabry-medium-pro';
  src: url('./assets/fonts/mabry-medium-pro.eot');
  src: url('./assets/fonts/mabry-medium-pro.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/mabry-medium-pro.woff2') format('woff2'),
       url('./assets/fonts/mabry-medium-pro.woff') format('woff'),
       url('./assets/fonts/mabry-medium-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mabry-bold-pro';
  src: url('./assets/fonts/mabry-bold-pro.eot');
  src: url('./assets/fonts/mabry-bold-pro.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/mabry-bold-pro.woff2') format('woff2'),
       url('./assets/fonts/mabry-bold-pro.woff') format('woff'),
       url('./assets/fonts/mabry-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "mabry-regular-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

html {
  overflow: hidden;
}
body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "mabry-medium-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

