
#logo {
    display: block;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
}

.menu-content {
    text-align: left;
    padding: 0px 0px 0px 0px;
    margin: 0px;
    background-color: #0d4c5a;
}

#active-lesson button  {
    background-color: rgba(255, 255, 255, 0.2);;
}

.menu-content h2 {
    font-family: "mabry-regular-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    margin: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.active button {
    background-color: #3fb980;
}

.inactive button {
    background-color: #0d4c5a;
}

.section-header button {
    margin: 0px;
    text-align: left;
    color: white;
    border: none;
    width: 100%;
    padding: 16px 0px 16px 36px;
}

.section-header button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.active button:hover {
    background-color: #3fb980;
}

.section-item {
    margin: 0px;
    padding: 0px 0px 0px 0px;
    width: 100%;
}

.section-item button {
    margin: 0px;
    font-size: 17px;
    background-color: #0d4c5a;
    text-align: left;
    color: white;
    border: none;
    padding-left: 45px;
    height: 44px;
    width: 100%;
}

.section-item button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.finished-dot {
    height: 12px;
    width: 12px;
    background-color: #3fb980;
    border-radius: 50%;
    border: 1.5px solid #3fb980;
    margin-right: 16px;
    margin-top: 7px;
    float: right;
}

.unfinished-dot {
    height: 12px;
    width: 12px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    border: 1.5px solid #3fb980;
    margin-right: 16px;
    margin-top: 7px;
    float: right;
}

.sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.sidebar-sticky a #logo {
    margin: 0 auto;
    padding: 80px 0;
}

.store-links {
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-left: 20px;
    text-align: center;
}