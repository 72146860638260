.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-container {
  margin: 88px 5% 88px 5%;
}

@media (min-width: 1400px) {
  .content-container {
    margin-right: 0 auto;
    width: 800px;
  }
}

.App-link {
  color: #61dafb;
}

.container-fill {
  min-height: 100vh;
}

.side-menu {
  background-color: #0d4c5a;
  padding: 0px;
  color: white;
  -ms-flex: 0 0 370px;
  flex: 0 0 370px;
}

@media (max-width: 690px) {
  .side-menu {
    display: none; 
  } 
}

.side-menu a {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-button {
  margin: 0px auto;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 10px 24px 10px 24px;
  font-size: 17px;
  background-color: #3fb980;
  text-align: center;
  color: white;
  border: none;
  border-radius: 22px;
}

.action-button:hover {
  background-color: rgba(63, 185, 128, 0.9);
}

h1 h2 h3 h4 {
  font-family: "mabry-medium-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #010101;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 36px;
  margin-bottom: 16px;
}

h2 {
  font-size: 28px;
  margin-top: 24px;
  margin-bottom: 8px;
}

ul {
  line-height: 2.0;
}

a {
  color: rgba(63, 185, 128, 0.9);
}

a:hover {
  color: #0d4c5a;
}

::selection {
  background: #F6C965;
}
::-moz-selection {
  background: #F6C965;
}

